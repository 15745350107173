import { Component, Fragment } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import milkCarton from "../images/milk.svg";

class NotFound extends Component {  
    render() {
        const body = document.body;
        body.classList.replace("theme-dark", "theme-light");
               
        return (
            <Container>
                <Fragment>
                    <div className="main-wrap">
                        <div className="row">
                            <div
                                className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
                                style={{backgroundColor: "#f5bf42"}}
                            >
                                <img src={milkCarton}/>
                            </div>
                            <div
                                className="col-xl-7 vh-100 align-items-center d-flex rounded-3 overflow-hidden"
                                style={{backgroundColor: "#3a044a"}}
                            >
                                <div className="card shadow-none border-0 ms-auto me-auto login-card bg-transparent">
                                    <div className="card-body rounded-0 text-left">
                                        <h2 className="text-warning fw-700 display1-size display2-md-size mb-4">
                                            {"¯\\_(ツ)_/¯"}
                                        </h2>
                                        <br/>
                                        <h2 className="text-white fw-700 display1-size display2-md-size mb-4">
                                            Hmm... We don't seem to have the page you're looking for.
                                        </h2> 
                                        <br className="p-4"/>                                         
                                        <RedirectButton href="https://joinblynk.com/events">
                                            {"Go Back"}
                                        </RedirectButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            </Container>
        );
    }
}

const Container = styled.div`
    padding: 0px;
`;

const RedirectButton = styled.a`
    display: flex;
    justify-content: center;
    color: #ffffff;
    background-color: #000000;
    align-items: center;
    height: 56px;
    width: 100%;
    border-radius: 28px;
    margin-bottom: 16px;
    box-shadow: inset 0 0 0 1px rgb(0 0 0 / 60%),
        inset 0 0 0 2px rgb(0 0 0 / 0%) inset 0 0 0 1px rgb(0 0 0 / 0);
    vertical-align: middle;
    z-index: 0;
    transition-duration: 167ms;
    font-size: 20px;
    img {
        width: 36px;
        height: 36px;
    }
    &:hover {
        background-color: rgba(207, 207, 207, 0.25);
        color: rgba(0, 0, 0, 0.75);
    }
`;

export default NotFound;