// User Reducer Types
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const SET_USER = "SET_USER";
export const LOADING_USER = "LOADING_USER";

//UI Reducer Types
export const SET_ERRORS = "SET_ERRORS";
export const LOADING_UI = "LOADING_UI";
export const STOP_LOADING_UI = "STOP_LOADING_UI";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

// Data Reducer Types
export const LOADING_DATA = "LOADING_DATA";
export const SET_MEETING_SIGNATURE = "SET_MEETING_SIGNATURE";
export const SET_MEETING_ZAK = "SET_MEETING_ZAK";






