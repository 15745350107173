import { connect } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";
import { reveal } from "../../util/cryptography";
import { useState } from "react";

const Navbar = () => {

    let logoUrl;
    let darkBackground = false;

    const [showNavbar, setShowNavbar] = useState(true);

    try {
        const queryString = window.location.search.substring(3, window.location.search.length);
        const meetingData = JSON.parse(reveal(queryString));
        logoUrl = meetingData.headerLogoUrl;
        if (meetingData.darkBackground) {
            darkBackground = meetingData.darkBackground;
        }    
    } catch (error) {
        console.error(error);
    }  

    return (
        <>
        <ContainerWrapper
            onMouseOver={() => setShowNavbar(false)}
            onMouseOut={() => setShowNavbar(true)}
            style={{
                height: showNavbar? "120px": "0",
            }}
        >
           { showNavbar && <Container darkBackground={darkBackground || !logoUrl} className="navbar-container">
                <Content>             
                    <Logo className="cursor-pointer">
                        <a
                            to={`${
                                logoUrl
                                    ? "https://joinblynk.com/feed"
                                    : "https://joinblynk.com/landing"
                            }`}
                        >
                            <img
                                src={logoUrl? logoUrl: "/images/blynk_gold.png"}
                                style={{ maxWidth: "65vw", maxHeight: "75px" }}
                                alt=""
                                className="p-2"
                            />
                        </a>
                    </Logo>                     
                </Content>
            </Container>}
        </ContainerWrapper>
        <WrapperBoundary
            onMouseOver={() => setShowNavbar(true)}
        />
        </>
    );
};

const ContainerWrapper = styled.div`
    background: transparent;
    border: none;
    bottom-margin: 24px;
    left: 0;
    padding: 0 24px;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 100;
`;

const WrapperBoundary = styled.div`
    background: transparent;
    border: none;
    left: 0;
    position: fixed;
    top: 120px;
    width: 100vw;
    height: 65px;
    z-index: 200;
`;

const Container = styled.div`
    background: ${({ darkBackground }) => darkBackground? "linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2))": "linear-gradient(to right, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.2))"};
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    bottom-margin: 24px;
    left: 0;
    padding: 0 24px;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 100;
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center; // Remove to align left again
    margin: 0 auto;
    min-height: 100%;
    max-width: 1128px;
`;

const Logo = styled.span`
    margin-right: 8px;
    font-size: 0px;
`;

Navbar.propTypes = {
    user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
});

export default connect(mapStateToProps, null)(Navbar);
