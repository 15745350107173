import axios from "axios";
import {
    SET_USER,
    LOADING_USER    
} from "../types";

export const getUserData = () => (dispatch) => {
    dispatch({type: LOADING_USER});
    axios.get("/user")
        .then(res => {
            dispatch({
                type: SET_USER,
                payload: res.data
            });
        })
        .catch(err => {
            console.log(err);
        });
};