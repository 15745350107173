import { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMeetingSignature, getMeetingZAK } from "../../redux/actions/dataActions";
import { reveal } from "../../util/cryptography";
import ProgressDialog from "../../util/ProgressDialog";

const MeetingClient = (props) => {

    let meetingData = {};
    const queryString = window.location.search.substring(3, window.location.search.length);

    const showMeetingWindow = () => {
        document.getElementById("zmmtg-root").style.display = "block";
    };

    const hideMeetingWindow = () => {
        document.getElementById("zmmtg-root").style.display = "none";
    };

    try {
        meetingData = JSON.parse(reveal(queryString));        
    } catch (error) {
        console.error(error);
        hideMeetingWindow();
        window.location.replace("/404");
    }  
    
    useEffect(() => {
        const ZoomMtg = window.ZoomMtg;

        showMeetingWindow();

        // Load Meeting SDK libraries from the Zoom CDN
        ZoomMtg.setZoomJSLib("https://source.zoom.us/2.5.0/lib", "/av");
        ZoomMtg.preLoadWasm();
        ZoomMtg.prepareWebSDK();

        // loads language files, also passes any error messages to the ui
        ZoomMtg.i18n.load("en-US");
        ZoomMtg.i18n.reload("en-US");

        startMeeting(ZoomMtg);
    }, [props.signature]);

    const startMeeting = (meeting) => {
        const sdkKey = "5vYJKP4naqm6fJK1Xj2o9b7SSEMx9yOxn9ij";
        const { email, handle, meetingNumber } = meetingData; 
        const roleName = meetingData.roleName? meetingData.roleName: "participant";
        props.getMeetingZAK();
        props.getMeetingSignature(parseInt(meetingNumber), roleName);
        const leaveUrl = window.location.href;

        meeting.init({
            leaveUrl: leaveUrl,
            success: (success) => {
                console.log(success);

                meeting.join({
                    signature: props.signature,
                    meetingNumber,
                    userName: handle,
                    sdkKey,
                    userEmail: email, // Required for webinars
                    passWord: meetingData.password? meetingData.passWord: "",
                    zak: props.zak,
                    success: (success) => {
                        console.log(success);
                    },
                    error: (error) => {
                        console.error(error);
                    },
                });
            },
            error: (error) => {
                console.error(error);
            },
        });
    };

    return props.signature? null: <ProgressDialog />;
};

MeetingClient.propTypes = {
    user: PropTypes.object.isRequired,
    meetingNumber: PropTypes.string.isRequired,
    roleName: PropTypes.string.isRequired,
    signature: PropTypes.string.isRequired,
    zak: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
    user: state.user,
    signature: state.data.signature,
    zak: state.data.zak
});

export default connect(mapStateToProps, { getMeetingSignature, getMeetingZAK })(MeetingClient);
