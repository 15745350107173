import {
    LOADING_DATA,
    SET_MEETING_SIGNATURE,
    SET_MEETING_ZAK
} from "../types";

const initialState = {
    loading: false,
    profile: {}
};

export default function(state = initialState, action) {
    switch(action.type) {
        case LOADING_DATA:
            return {
                ...state,
                loading: true
            };   
        case SET_MEETING_SIGNATURE:
            return {
                ...state,
                signature: action.payload
            };
        case SET_MEETING_ZAK:
            return {
                ...state,
                zak: action.payload
            };
        default:
            return state;
    }
};