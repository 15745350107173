import React, { useEffect, useState } from "react";
import styled from "styled-components";

// UI Components
import Navbar from "../components/layout/Navbar";
import MeetingClient from "../components/events/MeetingClient";
import ErrorBoundary from "../util/ErrorBoundary";

const Event = () => {
    const [joinedMeeting, setJoinedMeeting] = useState(false);
    const meetingNumber = window.location.pathname.split("/")[2];

    const goToMeeting = () => {
        setJoinedMeeting(!joinedMeeting);
    };

    useEffect(() => {
        goToMeeting();
    }, []);

    return (
        <Container className="main-content right-chat-active">
            <Navbar />
            {joinedMeeting && (
                <ErrorBoundary>
                    <MeetingClient
                        meetingNumber={meetingNumber}
                        roleName="participant"
                    />
                </ErrorBoundary>
            )}
        </Container>
    );
};

const Container = styled.div`
    padding-top: 88px;
    max-width: 100%;
    height: 100vh;
    padding-left: 16px !important;
    padding-right: 16px !important;
    padding-bottom: 110px;
`;

export default Event;
