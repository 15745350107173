import "./App.css";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import { Component } from "react";
import jwtDecode from "jwt-decode";
import axios from "axios";
import { auth } from "./util/firebase";

// Redux
import { Provider } from "react-redux";
import store from "./redux/store";
import { SET_AUTHENTICATED } from "./redux/types";
import { getUserData } from "./redux/actions/userActions";

// Pages
import NotFound from "./pages/NotFound";
import Event from "./pages/Event";

// Components
import ScrollToTop from "../src/components/layout/ScrollToTop";

let token = localStorage.FBIdToken;

// Prevent proxy from including url path parameters in axios requests
axios.defaults.baseURL =
    "https://us-central1-blynk-781cc.cloudfunctions.net/api";

if (token) {
    const decodedToken = jwtDecode(token);

    // Check whether the current user's Firebase ID token is expired
    if (decodedToken.exp * 1000 < Date.now()) {
        auth.onIdTokenChanged((user) => {
            if (user) {
                user.getIdToken(true).then((idToken) => {
                    token = idToken.trim();
                    localStorage.setItem("FBIdToken", `Bearer ${token}`);
                    axios.defaults.headers.common[
                        "Authorization"
                    ] = `Bearer ${token}`;
                    store.dispatch(getUserData());
                    store.dispatch({ type: SET_AUTHENTICATED });
                });
            }
        });
    } else {
        axios.defaults.headers.common["Authorization"] = token;
        store.dispatch(getUserData());
        store.dispatch({ type: SET_AUTHENTICATED });
    }
}

class App extends Component {
    render() {
        return (
            <>
                <Provider store={store}>
                    <Router>
                        <ScrollToTop />
                        <div className="container" style={{ bottom: "0" }}>
                            <Routes>
                                <Route path="/" element={<Event />} />
                                <Route path="*" element={<NotFound />} />
                                <Route path="/events/:id" element={<Event />} />                                               
                            </Routes>
                        </div>
                    </Router>
                </Provider>
            </>
        );
    }
}

export default App;
