import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <App />, // Removed React.Strictmode tag due to third-party package incompatibility
  document.getElementById('root')
);

if ('serviceWorker' in navigator) {

    navigator.serviceWorker.getRegistrations().then(function(registrations) {

    for(let registration of registrations) {

            registration.unregister()

    }}).catch(function(err) {

        console.log('Service Worker registration failed: ', err);

    });
}

// TODO: continue here: https://github.com/GoogleChromeLabs/sw-precache/issues/340
// https://web.dev/coop-coep/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
