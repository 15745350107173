import {
    CLEAR_ERRORS,   
    SET_MEETING_SIGNATURE,
    SET_MEETING_ZAK
 } from "../types";
import axios from "axios";

export const clearErrors = () => (dispatch) => {
    dispatch({type: CLEAR_ERRORS});
};

export const getMeetingSignature = (meetingNumber, roleName) => (dispatch) => {
    const meetingDetails = {
        meetingNumber,
        role: roleName, // Either "participant" or "host"
    };
    axios.post("/meeting-sig", meetingDetails)
        .then((res) => {
            dispatch({
                type: SET_MEETING_SIGNATURE,
                payload: res.data.signature
            })
        })
        .catch((err) => {
            console.error(err);
        });        
};

export const getMeetingZAK = () => (dispatch) => {
    axios.get("/events/zak")
        .then((res) => {
            dispatch({
                type: SET_MEETING_ZAK,
                payload: res.data.token
            });
        })
        .catch((err) => {
            console.error(err);
        });
};